<template>
  <div>
    <div class="field-title has-text-weight-semibold">Transit Number</div>
    <ValidationProvider rules="transitNumber" v-slot="{ errors, passed, failed }">
      <div class="field transit-number-field">
        <div class="control has-icons-right">
          <input
            :class="`input is-medium-height ${classForField({ passed, failed })}`"
            type="number"
            placeholder="e.g. 00333"
            v-model.trim="transitNumber"
            @keydown="excludeNoneNumberInput"
            @input="onInput('transitNumber')">
          <span class="icon is-small is-right">
            <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;">
            <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
          </span>
        </div>
        <p class="help is-danger pl-2" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
    <div class="field-title has-text-weight-semibold">Routing / Institution Number</div>
    <ValidationProvider rules="institutionNumber" v-slot="{ errors, passed, failed }">
      <div class="field institution-number-field">
        <div class="control has-icons-right">
          <input
            :class="`input is-medium-height ${classForField({ passed, failed })}`"
            type="number"
            placeholder="e.g. 001"
            v-model.trim="institutionNumber"
            @keydown="excludeNoneNumberInput"
            @input="onInput('institutionNumber')">
          <span class="icon is-small is-right">
            <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;">
            <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
          </span>
        </div>
        <p class="help is-danger pl-2" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
    <div class="field-title has-text-weight-semibold">Account Number</div>
    <ValidationProvider rules="accountNumber" v-slot="{ errors, passed, failed }">
      <div class="field account-number-field">
        <div class="control has-icons-right">
          <input
            :class="`input is-medium-height ${classForField({ passed, failed })}`"
            type="number"
            placeholder="e.g. 001234"
            v-model.trim="accountNumber"
            @keydown="excludeNoneNumberInput"
            @input="onInput('accountNumber')">
          <span class="icon is-small is-right">
            <img v-show="failed" src="@assets/icons/exclamation.svg" alt="is-danger" style="width: 30px;">
            <img v-show="passed" src="@assets/icons/check.svg" alt="is-success" style="width: 16px;">
          </span>
        </div>
        <p class="help is-danger pl-2" v-show="failed">{{errors[0]}}</p>
      </div>
    </ValidationProvider>
  </div>
</template>
<script>
import formMixins from '@utils/mixins/form-mixins.js'

export default {
  mixins: [formMixins],
  data() {
    return {
      transitNumber: undefined,
      institutionNumber: undefined,
      accountNumber: undefined,
    }
  },
  methods: {
    onInput(field) {
      this.$emit('onInput', field, this[field])
    },
    excludeNoneNumberInput(event) {
      const key = event.key
      const exclusionList = ['e', 'E', '+', '-', '.']
      if (exclusionList.includes(key)) {
        event.preventDefault()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.field-title {
  font-size: 16px;
  padding: 10px 0 12px 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
