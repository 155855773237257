<template>
  <div class="bank-details">
    <NavBack/>
    <div class="sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <ValidationObserver ref="addAccountForm" v-slot="{ valid }" class="is-inline-block account-form-component">
        <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
        <div class="main has-background-white">
          <div class="has-text-weight-semibold box-title has-text-centered">Manually link your account</div>
          <div class="box-subtitle has-text-centered">What are your bank details for that financial institution?</div>
          <AccountFields @onInput="onAccountFieldInput"></AccountFields>
        </div>
        <BottomButtons
          :showSteps="false"
          :nextDisabled="!valid"
          @goNext="toConfirmationPage"
          @goBack="goBack"
        ></BottomButtons>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import AccountFields from './AccountFields.vue'
import BottomButtons from '@components/button/bottom-buttons.vue'
import { encode } from 'js-base64'

export default {
  data() {
    return {
      transitNumber: undefined,
      institutionNumber: undefined,
      accountNumber: undefined,
    }
  },
  components: {
    NavBack,
    MenuAndLogo,
    AccountFields,
    BottomButtons,
  },
  methods: {
    onAccountFieldInput(field, value) {
      this[field] = value
    },
    goBack() {
      this.$router.go(-1)
    },
    toConfirmationPage() {
      const bank = JSON.parse(localStorage.getItem('bankAccount'))
      const account = {
        transitNumber: this.transitNumber,
        routingNumber: this.institutionNumber,
        accountNumber: this.accountNumber,
      }
      Object.assign(bank, account)
      this.$router.push({
        path: '/wallet/accounts/padAgreement',
        query: {
          params: encode(JSON.stringify(bank)),
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.bank-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      min-height: 426px;
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      margin-bottom: 27px;
      padding: 0 12px 20px;
      .box-title {
        font-size: 16px;
        padding-top: 24px;
      }
      .box-subtitle {
        padding: 8px 21px 43px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .bank-details {
    position: unset;
    padding-top: 19px;
  }
}
</style>
